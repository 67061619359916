import React                       from "react";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = [
  `"I have a beautiful memory of you sitting in my sun room with June asleep on your lap, hot tea and heart conversations abounding."`,
  `"When u sing A Whole New World to me on the phone"`,
  `"When we explored Brooklyn and nyc together and walked across the b bridge and got ice cream and pizza and cookies and cookies and pizza and pizza and ice cream =]"`,
  `"While I have over 25 years of memories with you, one of my favorites was when you met Liam for the first time. It meant so much that you came home to spend time with him. Love you!"`,
  `"Standing in a doorframe in the Hav taking pictures of our height or walking around campus and taking a picture with a tree. Always such a good tall buddy."`,
  `"Peeling parsnips!!"`,
  `"Traveling across California with you and Sarah and Ashley! And also all of our coffee dates in college."`,
  `"When we pretended to be each other at an OUAB meeting and then realized we both take pictures posed in front of brick walls wearing a striped shirt."`,
  `"Standing in a doorframe in the Hav taking pictures of our height or walking around campus and taking a picture with a tree. Always such a good tall buddy."`
];

const Rotate = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      10000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <h2 style={{minHeight:`150px`, padding:`5px`}}>
      <TextTransition
        text={ TEXTS[index % TEXTS.length] }
        springConfig={ presets.wobbly }
        style = {{fontFamily:`Cooper Hewitt`}}
      />
    </h2>
  );
};

export default Rotate;