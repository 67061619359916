import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from "gatsby"
import Layout2 from "../components/layout2"
import Footer from '../components/footer'
import './../css/memories.css'
import { Helmet } from "react-helmet"
import './../css/button.css'
import Button from './../elements/button.js'
import TextTransition, { presets } from "react-text-transition";
import Rotate from './../components/rotate'

export default class Memories extends PureComponent {
  
  state = {
    name: "",
    memory: "",
    message: "",
    success: false
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    console.log(this.state)
    event.preventDefault()
    fetch('/.netlify/functions/memory', {
      method: "POST",
      body: JSON.stringify({
        name: this.state.name,
        memory:this.state.memory,
      })
    })
    var messages = ["Thanks for submitting a mem!", "<3 <3 <3 <3. mem received.", "Got it! Thx so much! :)", "Wow, another mem for me? You shouldn't have!"]
    messages.pop(messages.indexOf(this.state.message))
    this.setState({success:true, message:messages[Math.floor(Math.random()*messages.length)], name:"", memory:""})
  }

  componentDidMount () {
    if (typeof window != 'undefined'){
      var pix = document.getElementsByClassName("pixel");

    for (var i = 0; i < pix.length; i++) {
      pix[i].style.animationDelay = Math.ceil(Math.random()*8000)+"ms";
    }
    }
}

render() {
  return (
    <Layout2>
    <StaticQuery
      query={graphql`
        query {
          fileName: file(relativePath: { eq: "img/projects/thoughtshero.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Helmet title="Brett Bejcek: Memories">
      <meta name="description" content="Be part of my morning routine." />
      <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Brett Bejcek: Memories" />
      <meta property="og:description" content="Be part of my morning routine." />
      <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="Brett Bejcek: Memories" />
      <meta name="twitter:description" content="Be part of my morning routine." />
      <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
        </Helmet>
      )}
    />
    <div css={{width:`100%`, marginLeft:`auto`, marginRight:`auto`, maxWidth:`700px`, padding:`30px`, position:'relative'}}>
    <div class="sidePanel" css={{position:`inherit`, marginBottom:`30px`}}>
    <div css ={{position:`absolute`, marginTop:`40px`, fontSize:`xx-large`, marginLeft:`30px`, textAlign:`center`, zIndex:3}}><p style={{background:`white`, color:`#7d4aa3`, padding:`5px`}}><i><b>MEMORIES</b></i><p style={{fontSize:`medium`, padding:`5px`}}>be part of my morning routine</p></p></div>
  <div class="pixelCon">
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
    <div class="pixel"></div>
  </div>
</div>
<div css = {{border:`#7d4aa3 solid 3px`, marginTop:`30px`, borderRadius:`16px`, padding:`10px`, marginBottom:`50px`}}>
<p css={{color:`#7d4aa3`, fontSize:`medium`, marginBottom:`0px`, marginTop:`15px`}}><b>TELL ME ABOUT A MEMORY YOU HAVE OF US</b></p>
<form css={{width:`100%`}} onSubmit={this.handleSubmit}>
<textarea name="memory" value={this.state.memory} onChange={this.handleInputChange} css={{width:`100%`, border:`#7d4aa3 solid 3px`, borderRadius:`16px`, padding:`10px`}} rows="5" placeholder="hi"></textarea>
<p css={{color:`#7d4aa3`, fontSize:`medium`, marginBottom:`0px`, marginTop:`15px`}}><b>NAME</b></p>
<input name="name" value={this.state.name} onChange={this.handleInputChange} css={{width:`100%`, border:`#7d4aa3 solid 3px`, borderRadius:`16px`, padding:`10px`}} placeholder="hi"></input>
<div css={{width:`100%`,marginTop:`20px`, marginBottom:`20px`, textAlign:`center`}}>
<button className = 'button' type="submit" style = {{backgroundColor: `#7d4aa3`, minWidth:`100px`,textDecoration:`none`, border: `none`, borderRadius:`20px`, color:`white`,fontSize:`normal`}} onClick={this.changeMessage}>submit</button>
<p css={{marginTop:`15px`, minHeight:`22px`}}>{this.state.message}</p>
</div>
</form>
</div>
<div css={{padding:`10px`}}>
<p css={{color:`#7d4aa3`, fontSize:`medium`, marginBottom:`0px`}}><b>WHY AM I DOING THIS?</b></p>
<p>The first thing I do in the morning is reach for my phone and start planning the day ahead of me.<br/><br/>
<b>I instead want to ground my day in the things that matter most: <i>the people I have met and the memories I have made.</i></b><br/><br/>
I am lucky enough to have an incredibly amazing friend <a href="https://daltonf.com/about/"><u>Dalton Flanagan</u></a> who gave me the world's most thoughtful gift: a hackable, digital photoframe that scrapes my personal data and visualizes how I am progressing toward my values that week.<br/><br/>
I am adding a new screen to this photoframe so that every morning I will get a random memory and my first thought of the day will be gratitude for the people in my life. Be part of my morning routine?</p>
<p css={{color:`#7d4aa3`, fontSize:`medium`, marginBottom:`0px`}}><b>UPDATE [02-20-2021]</b></p>
<p css={{marginBottom:`0`}}>I had 100+ people leave me a memory and I really think it has helped me in centering myself in the right things. Take a look below for a smattering of my favorites:<br/><br/></p>
</div>
<Rotate/>
<Button link = "/" cta = "back to home"/>

<div className = 'help' css = {{marginTop:`50px`,'@media (max-width: 750px)':{width:`110%`, marginLeft:`-5%`}}}>
    <Footer/>
    </div>
    </div>
    </Layout2>
)
}

}
